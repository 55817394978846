import React, { useState } from 'react';
import Web3 from 'web3';
import contractABI from './abis/migratoooor.json';

// Replace shadcn imports with basic HTML elements
const Card = ({ children, className }) => (
  <div className={`migratoor-container ${className || ''}`}>{children}</div>
);
const Button = ({ children, className, ...props }) => (
  <button className={`${className || ''}`} {...props}>{children}</button>
);
const Input = ({ className, ...props }) => (
  <input className={`${className || ''}`} {...props} />
);

// Update the contract address
const TOKEN_ADDRESS = "0x8b7007E1D02d8387B7B4BC8C6172598780ae59B2"; // FGHST token address
const CONTRACT_ADDRESS = "0x33f2AF2A443aDCcbF37f87208553AE2C894BEaF5"; // Migratoor contract address
const FANTOM_CHAIN_ID = '0xfa';  // 250 in hex
const FANTOM_RPC = 'https://rpcapi.fantom.network';
const APPROVE_METHOD_ID = '0x095ea7b3';

const GET_ALLOWANCE = '0xdd62ed3e'; // allowance(address,address)

// Replace the simple Web3 initialization with a more robust version
const getWeb3 = () => {
  if (window.ethereum) {
    return new Web3(window.ethereum);
  } else if (window.web3) {
    return new Web3(window.web3.currentProvider);
  }
  throw new Error('No Web3 provider detected');
};

// Update the web3 initialization
let web3;
try {
  web3 = getWeb3();
} catch (err) {
  console.error('Failed to initialize Web3:', err);
}

const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

async function ensureFantomChain() {
  try {
    // Try to switch to Fantom Opera
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: FANTOM_CHAIN_ID }],
    });
  } catch (switchError) {
    // If chain hasn't been added to MetaMask, add it
    if (switchError.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: FANTOM_CHAIN_ID,
          chainName: 'Fantom Opera',
          nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
          rpcUrls: [FANTOM_RPC],
          blockExplorerUrls: ['https://ftmscan.com/'],
        }],
      });
    }
  }
}

const MigratoorUI = () => {
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('');
  const [account, setAccount] = useState('');
  const [balance, setBalance] = useState('0');
  const [totalBurn, setTotalBurn] = useState('0');
  const [userBurns, setUserBurns] = useState('0');

  async function connectWallet() {
    try {
      if (!window.ethereum) {
        setStatus('Please install MetaMask');
        return;
      }
      await ensureFantomChain();
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts[0]);
      await getBalance(accounts[0]);
    } catch (err) {
      setStatus('Failed to connect: ' + err.message);
    }
  }

  async function getBalance(address) {
    try {
      const tokenContract = new web3.eth.Contract([{
        "constant": true,
        "inputs": [{"name": "account","type": "address"}],
        "name": "balanceOf",
        "outputs": [{"name": "","type": "uint256"}],
        "type": "function"
      }], TOKEN_ADDRESS);
      
      const balance = await tokenContract.methods.balanceOf(address).call();
      setBalance(web3.utils.fromWei(balance, 'ether'));
    } catch (err) {
      setStatus('Error fetching balance: ' + err.message);
    }
  }

  async function handleApprove() {
    try {
      const tokenContract = new web3.eth.Contract([{
        "constant": false,
        "inputs": [{"name": "spender","type": "address"},{"name": "amount","type": "uint256"}],
        "name": "approve",
        "outputs": [{"name": "","type": "bool"}],
        "type": "function"
      }], TOKEN_ADDRESS);
      
      const maxApproval = web3.utils.toWei('1000000000', 'ether'); // Large approval amount
      const data = tokenContract.methods.approve(CONTRACT_ADDRESS, maxApproval).encodeABI();
      
      const tx = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: account,
          to: TOKEN_ADDRESS,
          data: data
        }]
      });
      setStatus('Approval submitted: ' + tx);
      return true;
    } catch (err) {
      setStatus('Approval failed: ' + err.message);
      return false;
    }
  }

  async function getTotalBurn() {
    try {
      const totalBurn = await contract.methods.totalToBurn().call();
      const totalInEther = web3.utils.fromWei(totalBurn, 'ether');
      setTotalBurn(parseFloat(totalInEther).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      }));
    } catch (err) {
      console.error('Error fetching total burn:', err);
      setTotalBurn('0.0000');
    }
  }

  async function checkAllowance() {
    try {
      const tokenContract = new web3.eth.Contract([{
        "constant": true,
        "inputs": [{"name": "owner","type": "address"},{"name": "spender","type": "address"}],
        "name": "allowance",
        "outputs": [{"name": "","type": "uint256"}],
        "type": "function"
      }], TOKEN_ADDRESS);
      
      const allowance = await tokenContract.methods.allowance(account, CONTRACT_ADDRESS).call();
      return allowance;
    } catch (err) {
      console.error('Error checking allowance:', err);
      return 0;
    }
  }

  const getUserBurns = React.useCallback(async () => {
    if (!account) return;
    try {
      const userBurn = await contract.methods.totalBurnsByUser(account).call();
      const burnsInEther = web3.utils.fromWei(userBurn, 'ether');
      setUserBurns(parseFloat(burnsInEther).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      }));
    } catch (err) {
      console.error('Error fetching user burns:', err);
      setUserBurns('0.0000');
    }
  }, [account]);

  async function handleDeposit() {
    if (!amount || !account) {
      setStatus('Connect wallet and enter amount');
      return;
    }

    try {
      const amountWei = web3.utils.toWei(amount.toString(), 'ether');
      const currentAllowance = await checkAllowance();
      
      if (currentAllowance < amountWei) {
        const approved = await handleApprove();
        if (!approved) return;
      }

      // Use contract methods to encode the function call
      const data = contract.methods.deposit(account, amountWei).encodeABI();
      
      const tx = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: account,
          to: CONTRACT_ADDRESS,
          data: data
        }]
      });
      setStatus('Transaction submitted: ' + tx);
      
      await window.ethereum.request({
        method: 'eth_getTransactionReceipt',
        params: [tx],
      });
      await getTotalBurn();
      await getUserBurns();
    } catch (err) {
      setStatus('Error: ' + err.message);
    }
  }

  React.useEffect(() => {
    if (window.ethereum) {
      getTotalBurn();
      if (account) {
        getUserBurns();
      }
    }
  }, [account, getUserBurns]);

  return (
    <div className="app-wrapper">
      <div className="warning-banner">
        <div className="warning-icon">⚠️</div>
        <div className="warning-text">
          <strong>WARNING: One-Way Migration</strong>
          <p>This is a permanent, one-way migration. Tokens will be sent to the dead address and cannot be recovered. 
          There is no way to withdraw from this contract once tokens are deposited. See you on the other side!</p>
        </div>
      </div>

      <div className="header">
        <h1>Migratoor</h1>
        {account ? (
          <div className="wallet-info">
            <span className="wallet-address">
              {account.slice(0, 6)}...{account.slice(-4)}
            </span>
          </div>
        ) : (
          <button 
            onClick={connectWallet}
            className="wallet-button"
          >
            Connect Wallet
          </button>
        )}
      </div>

      <Card>
        <div className="card-content">
          <div className="balance">
            <span>Balance</span>
            <span className="balance-amount">{balance} FGHST</span>
          </div>

          <div className="input-group">
            <Input
              type="number"
              placeholder="Amount to Burn"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              max={balance}
            />
          </div>

          <Button 
            onClick={handleDeposit}
            disabled={!account}
            className="action-button"
          >
            Deposit
          </Button>

          {status && (
            <div className="status-message">
              <div className="status-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <p>{status}</p>
            </div>
          )}

          <div className="stats">
            <div className="stat-row">
              <span>Total to Burn</span>
              <span>{totalBurn}</span>
            </div>
            <div className="stat-row">
              <span>Your Burns</span>
              <span>{userBurns}</span>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MigratoorUI;