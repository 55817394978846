import MigratoorUI from './migrate.jsx';

function App() {
  return (
    <div className="app-container">
      <MigratoorUI />
    </div>
  );
}

export default App;
